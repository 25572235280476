import React from "react";
import OpenHours from "./OpenHours";

//<figure><img src="/images/stock/photo-1606107557195-0e29a4b5b4aa.jpg" alt="car!"/></figure>


function Contact() {
    return(
        <div>
            <div className="card lg:card-side glass shadow-2xl hover:shadow-2xl">

                <div className="max-md:hidden inline"><figure><img src="/schild.jpg" alt="Praxisschild"/></figure></div>
                <div className="md:hidden"><figure><img src="/schild_small.jpg" alt="Praxisschild"/></figure></div>
                <div className="card-body">
                    <h2 className="card-title">Kontaktdaten</h2>
                    <p>Büchelbach 18
                    <br/>63599 Biebergemünd - Bieber <br></br>
                        Tel.: <a className="hover:underline " href="tel:060501516">06050-1516</a><br></br>
                    Fax.:  <a className="hover:underline " href="tel:060502051">06050-2051</a></p>
                    <h2 className="card-title">Nur für Rezepte</h2>
                    <p>E-Mail: <br></br><a className="hover:decoration-pink-600 decoration-black underline" href="mailto:rezepte-dr-bonin@t-online.de">rezepte-dr-bonin@t-online.de</a></p>
                </div>
            </div>
        </div>
    );
}

function Announcement() {
    return(
        <div className="card bg-red-200 shadow-2xl hover:shadow-2xl">
            <div className="card-body">
                <h1 className="card-title">Ankündigung</h1>
                <p>
                Liebe Patient*innen: Im kommenden Jahr 2025 werden wir in das neue Ärzte- und Pflegehaus Biebertalstraße 13-15
                in Biebergemünd-Bieber, sobald es bezugsfertig ist, umziehen. <br></br><br></br>
                Aus aktuellem Anlass möchten wir Sie bitten, Wiederholungs- Rezepte und
                Überweisungen nur über unser Rezepttelefon und E-Mail
                anzufordern: <a className="underline hover:decoration-pink-600 decoration-black" href="mailto:rezepte-dr-bonin@t-online.de">rezepte-dr-bonin@t-online.de</a>.
                Andere Portale werden von uns nicht genutzt. Unser Telefon soll frei bleiben für Ihre Terminvereinbarungen usw. <br></br><br></br>
                Ihr Praxis-Team Dr. med. Thomas Bonin
                </p>
            </div>
        </div>
    );
}

function Rezeptbestellungen() {
    return(
        <div className="card bg-primary/50 shadow-2xl hover:shadow-2xl">
            <div className="card-body">
                 <div>
                     <h1 className="card-title">Rezeptbestellungen</h1>
                     <p>
                         Wir bieten Ihnen ein Rezept-Bestelltelefon mit Mailbox für außerhalb der Sprechzeiten an: <a className="font-bold hover:underline " href="tel:060506940977">06050-6940977</a>
                     </p>
                     <p>
                         Oder bestellen Sie per E-Mail: <a className="underline hover:decoration-pink-600 decoration-black" href="mailto:rezepte-dr-bonin@t-online.de">rezepte-dr-bonin@t-online.de</a>
                     </p><br/>
                     <div>Bitte bestellen Sie Ihre Dauer-Rezepte und Überweisungen rechtzeitig vorher. Die Lieferung dauert gewöhnlich 2-3 Tage nach Ihrer Bestelllung. An Urlaubstagen und Feiertagen sind Rezeptbestellungen nicht verfügbar.</div>
                </div>
            </div>
        </div>
    );
}
export default function HomePage() {
    //const [openNav, setOpenNav] = React.useState(false);

    //React.useEffect(() => {
    //    window.addEventListener(
    //        "resize",
    //        () => window.innerWidth >= 960 && setOpenNav(false)
    //    );
    //}, []);

//space-x-4
    return (
        <div className="container flex-1 overflow-y-auto mx-auto px-8 py-8">
            <div className="">

                <div className="max-lg:hidden inline flex flex-row lg:bg-fixed rounded-md px-8 py-16" style={{
                    position:`relative`,
                    backgroundOrigin: `padding-box`,
                    backgroundImage: `url("IMG_4268_1.jpg")` }}>
                    <div className="hero-overlay bg-opacity-60"></div>
                    <div className="hero-content max-lg:hidden inline ">
                        <OpenHours/>
                    </div>
                </div>
                <div className="lg:hidden">
                    <OpenHours/>
                </div>

                <div id="Rezeptbestellungen" className="py-8">
                    <Announcement/>
                </div>

                <div id="Rezeptbestellungen" className="py-2"></div>
                <div>
                    <Rezeptbestellungen/>
                    <div id="Kontakt" className="py-10"></div>
                    <Contact/>
                </div>
                <div className="py-10"></div>

            </div>
        </div>
    );
}

//function HomePage() {
//    return (
//        <div>
//            Among Us
//        </div>
//    )
//}

//export default HomePage
