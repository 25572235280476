import React from "react";
import {Link} from "react-router-dom";

export default function OpenHours() { //TODO: insert functionality pls for Öffnungszeiten
    //                   <div className="font-extrabold text-lime-800">Praxis ist momentan geöffnet!</div>
    function Click() {
        window.scrollTo(0, 0)
    }
    return(
            <div className="card lg:w-96 lg:backdrop-blur-xl lg:backdrop-brightness-150 shadow-2xl hover:shadow-2xl lg:bg-white/50">
                <div className="card-body self-center text-sm lg:text-base">
                    <h1 className="card-title text-xl lg:text-2xl">Öffnungszeiten</h1>
                    <div className=" grid grid-cols-2 lg:gap-x-20 gap-x-10 justify-items-stretch">
                        <div>Montag</div> <div className="font-bold">08:00–12:00</div>
                        <div></div> <div className="font-bold">16:00–18:00</div>
                    </div>
                    <div className=" grid grid-cols-2 lg:gap-x-20 gap-x-10 justify-items-stretch">
                        <div>Dienstag</div>	<div className="font-bold">08:00–12:00</div>
                        <div></div>	<div className="font-bold">16:00–18:00*</div>
                    </div>
                    <div className=" grid grid-cols-2 lg:gap-x-20 gap-x-10 justify-items-stretch">
                        <div>Mittwoch</div>	<div className="font-bold">08:00–12:00</div>
                    </div>
                    <div className="grid grid-cols-2 lg:gap-x-20 gap-x-10 justify-items-stretch">
                        <div>Donnerstag</div>	<div className="font-bold">08:00–12:00</div>
                        <div></div> <div className="font-bold">16:00–18:00</div>
                    </div>
                    <div className=" grid grid-cols-2 lg:gap-x-20 gap-x-10 justify-items-stretch">
                        <div>Freitag</div>	<div className="font-bold">08:00–12:00</div>
                    </div>
                    <div>Samstag und Sonntag geschlossen</div>
                    <div>
                        Beachten Sie unsere <Link to="/sprechstunden" className="link link-hover text-blue-800 underline decoration-2 hover:decoration-1" onClick={Click}>Urlaubstage</Link>
                        <Link to="/sprechstunden" className="link link-hover" onClick={Click}><label tabIndex={0} className="btn btn-circle btn-ghost btn-xs text-info ">
                            <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="w-4 h-4 stroke-black"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                        </label></Link>
                    </div>
                    <div>
                        *Nur nach vereinbarten Termin
                    </div>
                </div>
            </div>
    );
}
